import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '@/components/Layout';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';

const Page404 = () => (
  <Layout>
    <Helmet>
      <title>Get Your Team</title>
      <meta name="description" content="Get your team" />
    </Helmet>
    <Header />
    <div className="section__content">
      <p>The resource you are looking for does not exist</p>
    </div>
    <Footer />
  </Layout>
);

export default Page404;
